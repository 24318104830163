import React from 'react'
import styled from 'styled-components'
import styledContainerQuery from 'styled-container-query'
import Text from '../../../../../../components/Text'
import scale from './images/scale.svg'
import scaleVertical from './images/scale-vertical.svg'
import translate from '../../../../../../modules/translate'

const ImageMap = styled.div`
  padding-top: 5%;
  margin: 9px 15px 0 15px;
  overflow: hidden;
  background-image: url(${scaleVertical});
  background-repeat: no-repeat;
  background-size: 100%;
`

const ScaleData = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  left: 0;
  right: 0;
  margin: 0 auto;
  position: absolute;
`

const StyledText = styled(Text)`
  color: ${(props) => props.theme.color.textSecondary};
  font-weight: ${(props) => props.theme.fontWeight.bold};
  font-size: ${(props) => props.theme.fontSize.small};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.tablet.fontSize.small};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.mobile.fontSize.small};
  }
`

const ScaleDataItem = styled(Text)`
  color: ${(props) => props.theme.color.textSecondary};
  font-weight: ${(props) => props.theme.fontWeight.bold};
  font-size: ${(props) => props.theme.fontSize.small};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.tablet.fontSize.small};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.mobile.fontSize.small};
  }
  text-align: center;
  width: 46px;

  &:nth-child(2n) {
    display: none;
  }

  &:first-child {
    margin-left: 0;
  }
`
const ScaleContainer = styledContainerQuery.div`
  flex: 1;
  position: relative;
  width: 100%;
  padding-bottom: 1em;

  &:container(max-width: 73px) {
    display: flex;
    flex-direction: row;

    ${ImageMap} {
      width: 27px;
      height: calc(100% - 20px);
      padding: 9px 0;
      margin: auto 0;
      box-sizing: border-box;
      background-image: url(${scale});
      background-size: 16px 100%;
    }

    ${ScaleData} {
      position: static;
      flex-direction: column;
      justify-content: space-between;
    }

    ${ScaleDataItem} {
      display: block;
      margin: 0;
      text-align: left;
    }
  }
`
const Wrapper = styled.div`
  display: flex;
  flex-flow: column;
`

const ScaleRadarRainfall = ({ ...props }) => {
  return (
    <Wrapper {...props}>
      <StyledText>{translate('weather_rainfall_radar_unit')}</StyledText>
      <ScaleContainer>
        <ImageMap />
        <ScaleData>
          <ScaleDataItem>0,1</ScaleDataItem>
          <ScaleDataItem>2,0</ScaleDataItem>
          <ScaleDataItem>7,0</ScaleDataItem>
          <ScaleDataItem>16,0</ScaleDataItem>
          <ScaleDataItem>30,0</ScaleDataItem>
          <ScaleDataItem>70,0</ScaleDataItem>
          <ScaleDataItem>&gt;120,0</ScaleDataItem>
        </ScaleData>
      </ScaleContainer>
    </Wrapper>
  )
}

export default ScaleRadarRainfall
export { ImageMap as ImageMap0ScaleRadarRainfall }
