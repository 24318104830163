import styled from 'styled-components'
import PropTypes from 'prop-types'

const TitlePage = styled.h1`
  font-family: ${(props) => props.theme.font.primary};
  font-size: ${(props) => props.theme.fontSize.title1};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.tablet.fontSize.title1};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.mobile.fontSize.title1};
  }
  font-weight: ${(props) => props.theme.fontWeight.bold};
  color: ${(props) => props.theme.color.textPrimary};
  margin: 0;
`

TitlePage.propTypes = {
  children: PropTypes.node,
}

export default TitlePage
