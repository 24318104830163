import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Title from '../../../../../../components/Title'
import Text from '../../../../../../components/Text'

const StyledTitle = styled(Title)`
  font-size: ${(props) => props.theme.fontSize.text};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.tablet.fontSize.text};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.mobile.fontSize.text};
  }
`
const Wrapper = styled.div``

const InfoRadarRainfall = ({ title, text, ...props }) => (
  <Wrapper {...props}>
    <StyledTitle tag="p">{title}</StyledTitle>
    <Text>{text}</Text>
  </Wrapper>
)

InfoRadarRainfall.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
}

export default InfoRadarRainfall
