import React from 'react'
import {
  RadarRainfall0ContainerArticle,
  RadarRainfall0StyledTitlePage,
  RadarRainfall0Wrapper,
  RadarRainfall0WrapperRadar,
  RadarRainfall0WrapperRadarImages,
  ScaleRadarRainfall0StyledScaleRadarRainfall,
} from '../../../../components/RadarRainfall/index'
import TextSkeleton from '../../../../../../components/Text/views/TextSkeleton/index'
import styled from 'styled-components'
import RadarBackground from '../../../../components/RadarRainfall/components/ImageRadarRainfall/images/radar_background.jpg'

const StyledRadarRainfall0StyledTitlePage = styled(RadarRainfall0StyledTitlePage)``
const StyledRadarRainfall0WrapperRadarImages = styled(RadarRainfall0WrapperRadarImages)`
  div {
    max-width: 100%;
  }
`

const ImageRadarRainfallSkeleton = styled.div`
  width: 625px;
  padding-top: 100%;
  background: url(${RadarBackground}) no-repeat;
  background-size: contain;
  opacity: 0.5;
`

const StyledScaleRadarRainfall0StyledScaleRadarRainfall = styled(ScaleRadarRainfall0StyledScaleRadarRainfall)`
  filter: grayscale(100%);
  opacity: 0.7;
`

const RadarRainfallSkeleton = ({ ...props }) => (
  <RadarRainfall0Wrapper {...props}>
    <RadarRainfall0ContainerArticle>
      <StyledRadarRainfall0StyledTitlePage as={TextSkeleton} fontSize="title1" lastWidth="60%" />
      <RadarRainfall0WrapperRadar>
        <StyledRadarRainfall0WrapperRadarImages>
          <ImageRadarRainfallSkeleton />
        </StyledRadarRainfall0WrapperRadarImages>
        <StyledScaleRadarRainfall0StyledScaleRadarRainfall />
      </RadarRainfall0WrapperRadar>
    </RadarRainfall0ContainerArticle>
  </RadarRainfall0Wrapper>
)

export default RadarRainfallSkeleton
