import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import TextCopyrightWeather from '../../../../components/TextCopyrightWeather/index'
import RadarRainfallSkeleton from './skeleton'
import RadarRainfallComponent from '../../../../components/RadarRainfall/index'
import ErrorBoundary from '../../../../../Error/widgets/ErrorBoundary/index'
import translate from '../../../../../../modules/translate/index'
import MessageError from '../../../../../Error/screens/MessageError/index'
import ContainerAd from '../../../../../../components/ContainerAd/index'

const Wrapper = styled.div``

const StyledContainerAd = styled(ContainerAd)``

const RadarRainfall = ({ loading, failed, data, ...props }) => {
  delete props.staticContext
  const errorOptions = {
    component: translate('weather_rainfall_radar_error'),
    link: '/',
    linkText: translate('error_try_homepage'),
    logError: 'RadarRainfall sub-screen display',
  }

  return (
    <Wrapper {...props}>
      <ErrorBoundary error={failed} {...errorOptions}>
        {loading ? (
          <RadarRainfallSkeleton />
        ) : data ? (
          <RadarRainfallComponent items={data.images ? data.images : data} />
        ) : (
          <MessageError logInfo={translate('dev_errorlog_no_data')} {...errorOptions} />
        )}
      </ErrorBoundary>
      <StyledContainerAd adIds={['S7']} />
      <TextCopyrightWeather />
    </Wrapper>
  )
}

RadarRainfall.propTypes = {
  loading: PropTypes.bool,
  failed: PropTypes.bool,
  data: PropTypes.array,
}

RadarRainfall.defaultProps = {
  loading: false,
  failed: false,
}

export default RadarRainfall
