import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import RadarBackground from './images/radar_background.jpg'

const Wrapper = styled.div`
  background: url(${RadarBackground}) no-repeat;
  background-size: contain;
`

const StyledImage = styled.img`
  display: block;
`

const ImageRadarRainfall = ({ image, alt, ...props }) => (
  <Wrapper {...props}>
    <StyledImage src={image} alt={alt} />
  </Wrapper>
)

ImageRadarRainfall.propTypes = {
  image: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
}

export default ImageRadarRainfall
