import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Button from '../../../../../../components/Button'
import RangeSlider from '../../../../../../components/RangeSlider'
import PlayIcon from './images/play.svg'
import PauseIcon from './images/pause.svg'
import RightIcon from './images/right.svg'
import LeftIcon from './images/left.svg'
import translate from '../../../../../../modules/translate'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`
const PlayPauseButton = styled(Button)`
  margin-right: 10px;
`
const StyledRangeSlider = styled(RangeSlider)`
  margin: 0 10px;
`
const ButtonImage = styled.img`
  width: 15px;
  height: 15px;
  display: block;
`

class ControlsRadarRainfall extends Component {
  render() {
    const { playIcon, playPauseClick, previousClick, nextClick, slider, ...props } = this.props
    return (
      <Wrapper {...props}>
        <PlayPauseButton
          view="block"
          aria-label={translate('weather_rainfall_radar_pause_start')}
          onClick={playPauseClick}
        >
          <ButtonImage
            alt={translate('weather_rainfall_radar_pause_start')}
            src={(playIcon && PlayIcon) || PauseIcon}
          />
        </PlayPauseButton>
        <Button view="block" aria-label={translate('weather_rainfall_radar_previous_image')} onClick={previousClick}>
          <ButtonImage alt={translate('weather_rainfall_radar_previous_image')} src={LeftIcon} />
        </Button>
        <StyledRangeSlider
          onChange={slider.onChange}
          value={slider.value}
          text={slider.text}
          type="range"
          min={slider.min}
          max={slider.max}
        />
        <Button aria-label={translate('weather_rainfall_radar_next_image')} view="block" onClick={nextClick}>
          <ButtonImage alt={translate('weather_rainfall_radar_next_image')} src={RightIcon} />
        </Button>
      </Wrapper>
    )
  }
}

ControlsRadarRainfall.propTypes = {
  playIcon: PropTypes.bool,
  slider: PropTypes.shape(RangeSlider.PropTypes),
}

export default ControlsRadarRainfall
